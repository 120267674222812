
const fortum = {
    pros: [
        "100 % fossilfri el",
        "Ingen bindningstid",
        "Rabatterad i 12 mån"
    ],
    info: {
        agreement:"Rörligt Pris",
        termination:"2 mån",
        bindingTime:"0 mån",
        price:"35-45 kr"
    }
}

const vattenfall = {
    pros: [
        "100 % fossilfri el",
        "Ingen bindningstid",
        "Rabatterad i 12 mån"
    ],
    info: {
        agreement:"Rörligt Pris",
        termination:"2 mån",
        bindingTime:"0 mån",
        price:"45 kr"
    }
}

const greenely = {
    pros: [
        "El till självkostnadspris",
        "Analysera din elförbrukning",
        "Ingen bindningstid",
    ],
    info: {
        agreement:"Fast Pris",
        termination:"2 mån",
        bindingTime:"0 mån",
        price:"69 kr"
    }
}

export default {fortum,vattenfall,greenely}