import React from "react";
import Page from "../components/Page";
import { graphql, Link } from "gatsby";
import Affilate from "../components/Cards/Affilate"
import Hero from "../static/hero.inline.svg"
import companies from "../data/bolag"
import FAQ from "../components/FAQ";


export default function Index({ data }) {

  return (
    <>
      <Page
        title={"Jämför elavtal: Hitta det bästa alternativet för dig"}
        description="Jämför olika elavtalen och hitta det bästa och billigaste alternativet för dig."
      >
        <div className="hero bg-gray-100 py-16">
          <div className="container px-4 sm:px-8 lg:px-16 xl:px-20 mx-auto">
            <div className="hero-wrapper grid grid-cols-1 md:grid-cols-12 gap-8 items-center">

              <div className="hero-text col-span-6">
                <h1 className=" font-bold text-4xl md:text-5xl max-w-xl text-gray-900 leading-tight">Jämför elavtal</h1>
                <hr className=" w-12 h-1 bg-orange-500 rounded-full mt-8" />
                <p className="text-gray-800 text-base leading-relaxed mt-8 font-semibold">Jämför de olika elavtalen och hitta det bästa och billigaste alternativet för dig.</p>
                <div className="flex space-x-2 mt-6 justify-start">
                  <button type="button" className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">Läs mer</button>
                  <Link to={"/recensioner/"}><button type="button" className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-600 dark:focus:ring-blue-800">Se recensioner</button></Link>
                </div>
              </div>
              <div className="hero-image col-span-6">
                <img src={Hero}  alt="hero"/>
              </div>
            </div>
          </div>
        </div>

        <div className="container px-4 py-8 sm:px-8 lg:px-16 xl:px-20 mx-auto">
          <Affilate image={data.fortum} link={"/recensioner/fortum/"} companyUrl={"https://www.fortum.se"} pros={companies.fortum.pros} name={"Fortum"} description="Fortum är ett ledande energibolag som utvecklar och erbjuder sina kunder lösningar inom el, värme, kyla, återvinning och avfallshantering." />
          <Affilate image={data.vattenfall} link={"/recensioner/vattenfall/"} companyUrl={"https://www.vattenfall.se"} pros={companies.vattenfall.pros} name={"Vattenfall"} description="Vattenfall är ett energiföretag som är helägt av svenska staten. Företaget är producent av elektrisk energi, fjärrvärmeleverantör samt är elnätsägare." />
          <Affilate image={data.greenely} link={"/recensioner/greenely/"} companyUrl={"https://www.greenely.se"} pros={companies.greenely.pros} name={"Greenely"} description="Greenely är ett elbolag som använder smart teknik för att hjälpa dig att spara pengar på din elräkning." />

        </div>

        <div className="container  px-5 mx-auto ">
                    <div>
                        <section className="text-gray-700">
                            <div className="container px-5 py-16 mx-auto">
                                <div className="text-center mb-20">
                                    <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
                                        Vanliga frågor
                                    </h1>
                                    <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                                        Nedan listar vi några av de vanligaste frågorna som vi brukar få.
                                    </p>
                                </div>
                                <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                                    <div className="w-full lg:w-1/2 px-4 py-1">
                                        <FAQ question={"Varför finns inte alla elavtal med?"} content={"Idag finns det över 100 elavtal på marknaden och vi försöker ständigt bygga ut vår tjänst för att täcka alla alternativen."} />
                                        <FAQ question={"Kan ni hjälpa mig välja elavtal?"} content={"Elavtalen.se är endast en informationstjänst och vi bidrar därför inte med någon rådgivning."} />
                                        <FAQ question={"När ska man teckna elavtal?"} content={"Om du ska flytta är det bra att teckna elavtal i så god tid som möjligt, men helst två veckor innan är en bra rekommendation."} />
                                    </div>
                                    <div className="w-full lg:w-1/2 px-4 py-1">
                                        <FAQ question={"Vad är skillnaden mellan elnät och elhandel?"} content={"Elnät är ledningarna som går hem till dig medan elhandeln är den el som du förbrukar och köper av ett elhandelsbolag."} />
                                        <FAQ question={"Vad händer om jag inte tecknat elavtal?"} content={"Om du inte tecknar något särskilt elhandelsavtal, kommer du ändå att få el till ditt hem. Det sker genom att din nätägare anvisar dig en elhandlare. En nackdel är att detta kan bli dyrare."} />
                                        <FAQ question={"När börjar ett elavtal gälla?"} content={"Tiden som det tar att få igång elen beror på vilket elnätsbolag som äger elnätet och vad de har för regler. Försök därför att vara ute i god tid."} />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    </div>

        <br />
      </Page>
    </>
  );
}

export const pageQuery = graphql`
  query home {
    homeTop: file(relativePath: { eq: "undraw_Savings_re_eq4w.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    fortum: file(relativePath: { eq: "fortum.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    vattenfall: file(relativePath: { eq: "vattenfall.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    greenely: file(relativePath: { eq: "greenely.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
