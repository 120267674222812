import React from "react";
import { Link } from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image"

export default function Affilate({ pros = [], name, description, image, link, companyUrl}) {
  return (
    <div className="bg-white rounded-xl shadow-lg p-7 border border-gray-200 mt-8">

    <div className="flex ">
      <GatsbyImage backgroundColor="white" image={image.childImageSharp.gatsbyImageData} alt={name} className=" rounded-xl border-slate-700 border-1 hidden md:block md:w-64 md:h-auto mx-auto object-contain mr-8" objectFit="contain"/>
      <div className="md:text-left md:w-full space-y-4 ">
      <h2 className="text-2xl font-bold">{name}</h2>
        <p className="text-lg font-medium">
          {description}
        </p>
        <div className=" ">
          <Link to={link}><button type="button" className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">Läs recension</button></Link>
          <a href={companyUrl} target={"_blank"}>
            <button className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-600 dark:focus:ring-blue-800">
              Se elpris
            </button>
          </a>
        </div>

      </div>
      <div className="w-60 hidden hh-full   md:h-auto md:flex justify-center items-center h-screen">
        <div className="mr-3">
          <ul className="list-disc">
            {pros.map((p, i) => {
              return <li className="py-1" key={"index" + i}>{p}</li>
            })}
          </ul>
        </div>


      </div>

    </div>
    </div>
   
  );
}
